import { App as AntApp, ConfigProvider, Spin, ThemeConfig, theme } from 'antd'
import { Provider } from 'react-redux'
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom'
import type { History } from '@remix-run/router'
import { Suspense, useEffect } from 'react'
import { history } from '@manage/core/router/history'
import zhCN from 'antd/es/locale/zh_CN'
import useWatermark from '@manage/core/hooks/waterMark'
import ErrorBoundary from '@manage/core/monitor/ErrorBoundary'
import { CircleProgress } from '@manage/core/component/CircleProgress'
import store, { actions, dispatch, useSelector } from '@/store'
import MainLayout from './layouts/MainLayout'
import '@manage/core/tools/global'
import '@/assets/index.css'
import 'dayjs/locale/zh-cn'
import globalStyles from './globalStyles'
import Auth from './auth/Auth'

import request from '../core/request'
// 在import '@manage/core/tools/global'语句中注入了 $变量
// 但是这个系统的登录失效code比较特别 返回了400 重新注入了request
(window as any).$.fetch = request

Spin.setDefaultIndicator(
  <div className="flex items-center justify-center">
    <CircleProgress />
  </div>
)

const Index = () => {
  const { rtl, dark, primary, borderRadius } = useSelector(
    (state) => state.global.settings
  )
  const userInfo = useSelector(
    (state) => state.storage.userInfo
)
  const isLogin = useSelector(
    (state) => state.globalModel.isLogin
)

  useWatermark(`${(isLogin && userInfo?.userName) || ''}${(isLogin && userInfo?.dataEnc) || ''}`)
  useEffect(() => {
    if (userInfo?.userId) {
      window.collectEvent('config', {
        user_unique_id: userInfo.userId,
      })
    }
  }, [userInfo?.userId])
  const $theme: ThemeConfig = {
    algorithm: dark ? theme.darkAlgorithm : theme.defaultAlgorithm,
    token: {
      colorPrimary: primary ?? '#0E6CF0',
      borderRadius: borderRadius ?? 6,
      colorPrimaryText: dark ? '#146BE6' : '#1677FF',
      colorTextSecondary: dark
        ? 'rgba(255,255,255,.65)'
        : 'rgba(0, 0, 0, 0.45)',
      colorText: dark ? '#fff' : 'rgba(0,0,0,.85)',
      colorTextDescription: dark
        ? 'rgba(255,255,255,.65)'
        : 'rgba(0, 0, 0, 0.45)',
      colorTextTertiary: dark ? 'rgb(105, 117, 134)' : 'rgba(34, 51, 84, 0.7)',
    },
  }

  return (
    <Suspense>
      <AntApp style={{ height: '100%' }} className={globalStyles}>
        <ConfigProvider
          theme={$theme}
          locale={zhCN}
          direction={rtl ? 'rtl' : 'ltr'}
        >
          {/* <Auth bgUrl={`${CDN_PREFIX}assets/bg.png`} slogan={<Slogan />}> */}
          <Auth bgUrl={`https://cdn.yupaowang.com/yp-js-manage/images/yp_manage_start-loginBackImage.png`} logo={'https://cdn.yupaowang.com/yp-js-manage/images/yp_manage_startSystem-logo.png'}>
            <HistoryRouter history={history as unknown as History}>
              <MainLayout />
            </HistoryRouter>
          </Auth>
        </ConfigProvider>
      </AntApp>
    </Suspense>
  )
}

const App = () => (
  <ErrorBoundary>
    <Provider store={store}>
      <Index />
    </Provider>
  </ErrorBoundary>
)

export default App
