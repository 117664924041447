export const filterEmpty = (source: Record<string, any>) => {
    if (typeof source !== 'object') {
      return source
    }
    if (Array.isArray(source)) {
      return source
    }
    const result = { ...source }
    Object.keys(result).forEach(key => {
      if (
        !Object.prototype.hasOwnProperty.call(result, key) ||
        result[key] === '' ||
        result[key] === undefined ||
        result[key] === null
      ) {
        delete result[key]
      }
    })
    return result
  }