import { useEffect } from 'react'
import { Watermark, BlindWatermark, WatermarkOptions } from 'watermark-js-plus'

interface IUseWatermarkOption extends Omit<Partial<WatermarkOptions>, 'content'> {
  openBlindWatermark: boolean
  blindContent: string
  isHiddenWatermark?: boolean
}
const useWatermark = (content: string, options?: IUseWatermarkOption) => {
  const { openBlindWatermark = false, blindContent = '', fontColor, ...rest } = options || {}

  useEffect(() => {
    const watermark = new Watermark({
      content,
      width: 200,
      height: 200,
      rotate: 25,
      fontColor: 'rgba(150, 150, 150, 0.3)',
      fontSize: '14px',
      zIndex: 2147483640,
      onSuccess: () => {
        // success callback
      }
    })
    watermark.create()
    return (() => watermark.destroy())
  }, [content])


  useEffect(() => {
    let blindWatermark: BlindWatermark
    // 暗水印的容器 明水印 和 暗水印在同一个容器会被覆盖
    let blindWatermarkContainer: HTMLDivElement

    if (openBlindWatermark) {
      blindWatermarkContainer = document.createElement('div')
      document.body.appendChild(blindWatermarkContainer)

      blindWatermark = new BlindWatermark({
        parent: blindWatermarkContainer,
        contentType: 'multi-line-text',
        content: blindContent,
        width: 400,
        height: 200,
        rotate: 0,
        fontColor: fontColor || '#000',
        layout: 'grid',
        // globalAlpha: 0.02,
        gridLayoutOptions: {
          rows: 4,
          cols: 4,
          gap: [20, 20],
        },
        zIndex: 2147483650,
        onSuccess: () => {
          // success callback
          console.log('blindWatermark success')
          
        },
        ...(rest || {}),
      })
      blindWatermark?.create?.()
    }
    return () => {
      blindWatermark?.destroy?.()
      blindWatermarkContainer?.remove?.()
    }
  }, [openBlindWatermark, blindContent, fontColor])
}
export default useWatermark
