/*
 * @Author: sankki 1257477428@qq.com
 * @Date: 2023-10-25 14:09:54
 * @Description: 
 */
import { useState } from 'react'

const useLoadingHandle = <T extends ((...args: any[]) => Promise<any>)>(fn: T)  => {
  const [loading, setLoading] = useState<boolean>(false)
  const handle = async (...args: any[]) => {
    try {
      setLoading(true)
      return await fn(...args)
    } catch (error) {
      return Promise.reject(error)
    } finally {
      setLoading(false)
    }
  }
  return [loading, handle] as [boolean, T]
}

export default useLoadingHandle

