import { RouteObject } from 'react-router-dom'
import { Suspense, lazy } from 'react'
import { LoadingOutlined } from '@ant-design/icons'

type RouteType = {
  name?: string
  code?: string
  hidden?: boolean
  children?: RouteType[]
} & Omit<RouteObject, 'children'>

export interface MenuItemType extends RouteType {
  key: string
  label: string
  title: string
  type?: string
  children?: MenuItemType[]
}

/**
 * 给所有自路由添加suspense
 * @param routes
 * @returns
 */
export function completeSuspense(routes: RouteType[]): RouteType[] {
  return routes.map(each => {
    const El = each.Component!
    return {
      ...each,
      Component: El
        ? () => (
          <Suspense
            fallback={
              <div className="flex w-[100%] h-[100%] justify-center items-center">
                <LoadingOutlined />
              </div>
            }
          >
            <div style={{ height: '100%', padding: '0 36px 36px 36px' }}>
              <div style={{ minHeight: '100%', border: '1px solid rgba(139, 192, 255, 1)', padding: 32, borderRadius: 12, boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.05)', backgroundColor: 'rgba(255, 255, 255, 1)' }}><El /></div>
              <div style={{ height: 36 }}></div>
            </div>
          </Suspense>
        )
        : null,
      children: each?.children?.length ? completeSuspense(each.children) : [],
    }
  })
}

export function transMenuData(data: RouteType[], pathPrefix = '/'): MenuItemType[] {
  const $data = data.map(each => {
    const p = pathPrefix + each.path
    return {
      name: each.name,
      code: each.code,
      hidden: each.hidden,
      path: pathPrefix + each.path,
      key: p,
      children: each?.children?.length ? transMenuData(each.children, `${p}/`) : undefined,
      label: each.name!,
      title: each.name!,
    }
  })
  return $data
}

const routes: RouteType[] = [
  {
    path: 'manageCockpit',
    name: '',
    code: 'star_manageCockpit',
    children: [
      {
        path: 'clueManagement',
        name: '线索管理',
        code: 'star_clueManagement',
        children: [
          {
            path: 'clueEntry',
            name: '线索录入',
            code: 'star_clueEntry',
            children: [],
            Component: lazy(
              () => import('@/pages/pages/clueManagement/clueEntry/index')
            ),
          },
          // {
          //   path: 'clueStatistics',
          //   name: '线索统计',
          //   code: 'star_clueStatistics',
          //   children: [],
          //   Component: lazy(
          //     () => import('@/pages/pages/clueManagement/clueStatistics/index')
          //   ),
          // },
        ],
      },
      {
        path: 'task-center',
        name: '任务中心',
        code: 'star_taskCenter',
        children: [],
        Component: lazy(() => import('@/pages/pages/taskCenter')),
      },
    ],
  },
]

export const menus = transMenuData(routes)

export default completeSuspense(routes)
