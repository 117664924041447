/*
 * @Author: sankki 1257477428@qq.com
 * @Date: 2023-10-11 14:37:13
 * @Description: $
 */

import { history } from "../router/history"
import { Modal, ModalFuncProps, message } from "antd"
import request from "../request"

const { confirm: oldConfirm } = Modal

message.config({
  duration: 1.2,
})

const confirm = (e: ModalFuncProps) => {
  return new Promise<void>((resolve, reject) => {
    oldConfirm({
      onOk: async (...args) => {
        await e.onOk?.(...args)
        resolve()
      },
      onCancel: async (...args) => {
        await e.onCancel?.(...args)
        reject()
      },
      ...e,
    })
  })
}

const options = {
  /** 判断是否登录 */
  isLogin: !!/jwtToken=(.*)$/.exec(document.cookie),
  /** 接口请求 */
  fetch: request,
  /** 等待 */
  wait: (time = 100) => new Promise((resolve) => { setTimeout(resolve, time, null) }),
  /** toast */
  msg: message,
  /** 路由 */
  router: history,
  /** Modal */
  Modal: Modal as typeof Modal & ModalType,
  confirm,
};

(window as any).$ = options

declare global {
  const $: typeof options
}

export { }
