/*
 * @Date: 2023-08-28 17:09:17
 * @Description: 接口请求
 */

import axios, { AxiosRequestConfig } from 'axios'
import { API_PREFIX, BUSINESS } from '@/core/config'
import { dispatch, actions } from '@/store'
import { filterEmpty } from '../tools/object'
import { setCookie } from '@manage/core/tools/storage'

type IModels = { [key: string]: any }

type Config = {
  url: string
  method: string
  data: Record<string, any>
  options: {
    /** 是否显示loading */
    showMessage?: boolean
    axiosExtraOption?: AxiosRequestConfig<any>
    headers?: any // TODO
    /** 是否删除值为 null、undefined、'' 的字段 */
    filterEmptyData?: boolean
    /** 是否走apiFox去mock */
    isMock?: boolean
    baseUrl?: string
  }
}

interface DataRes<T> {
  code: number
  message: string
  data: T
  askId: string
  error: boolean
}

type Response = IModels & { [key: string]: any }

declare type Fetch = {
  [K in keyof Response]: (
    req?: K extends keyof IModels ? Req<K> : any,
    extra?: Config['options']
  ) => Promise<
    [K extends keyof IModels ? Res<K> : any, DataRes<K extends keyof IModels ? Res<K> : any>]
  >
}

const memoMessage = new Set<string>()
const debounceError = (message: string) => {
  if (memoMessage.has(message)) {return}
  $.msg.error(message)
  memoMessage.add(message)
  setTimeout(() => {
    memoMessage.delete(message)
  }, 500)
}

function ajax<T>(options: {
  url: any
  method: any
  data: any
  config: Config['options']
}): Promise<T> {
  const {
    url,
    method,
    data,
    config: { showMessage = true, headers = {}, axiosExtraOption, filterEmptyData, baseUrl, isMock },
  } = options
  const reqData = filterEmptyData ? filterEmpty(data) : data

  return axios({
    baseURL: baseUrl || API_PREFIX,
    url,
    method,
    params: method === 'get' ? reqData : undefined,
    data: method === 'post' ? reqData : undefined,
    withCredentials: true,
    headers: {
      business: BUSINESS,
      ...headers,
    },
    ...axiosExtraOption,
  })
    .then(({ status, data }) => {
      if (status === 200) {
        if (import.meta.env.DEV && isMock) {
          const resData = data.code == null && data.message == null ? { data, code: 0 } : data
          return [data, resData]
        }
        if (data.code === 0) {
          if (showMessage && data.message && !['SUCCESS', '请求成功'].includes(data.message)) {
            $.msg.success(data.message)
          }
          return [data.data, data]
        }
        // 错误toast提示
        if (showMessage && data.message) {
          debounceError(data.message)
        }
        // 已经退出登录
        // 后端code 不明确 先暂时使用message判断
        
        if (data.code === 601 || data?.message === 'token已失效,请重新登陆') {
          // 清除token
          // debounceError('请求失败')
          setCookie('jwtToken', '', {
            domain: '.starfilled.cn',
            expires: 1
          })
          dispatch(actions.global.setState({ tokenFailure: true }))
          dispatch(actions.globalModel.setIsLogin(false))
          dispatch(actions.storage.removeItem('userInfo'))
        }

        return Promise.reject(data) as any
      }

      throw Error('请求错误')
    })
    .catch(result => {
      // 接口异常，状态码不是200
      if (result.name === 'AxiosError') {
        const { message: msg } = result.response.data
        if (showMessage) {
          debounceError(msg || '请求失败')
        }
        return Promise.reject(result.response.data)
      }
      return Promise.reject(result)
    }) as Promise<T>
}

const request = new Proxy(
  {},
  {
    get(_, p) {
      return (data: any = {}, options = {}) => {
        const [method, ...urlArr] = (p as string).split('/')
        // 如果是开发环境，统一加一个 /api 前缀，用于代理到后端地址
        const url = `/${urlArr.join('/')}`
        return ajax({
          url,
          method: method.toLowerCase(),
          data,
          config: options,
        })
      }
    },
  }
) as Fetch

export default request
